<template>
  <div>
    <HgButton id="update-profile-button" color="teal" @onClick="isModalOpen = true">
      {{ $t("raffle.completeYourProfileButton") }}
    </HgButton>

    <HgModal v-if="isModalOpen" @onClose="isModalOpen = false">
      <div class="tw-p-4">
        <h2 class="hg-modal-title">
          {{ $t("profileForm.title") }}
        </h2>
        <HgUserProfileForm
          :user="user"
          :locations="locationOptions"
          @onSubmit="submit"
          @onChangeLocation="updateLocation"
        />
      </div>
    </HgModal>
  </div>
</template>

<script>
import searchLocationQuery from "../../../apollo/queries/searchLocation.gql";
export default {
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    isModalOpen: false,
    locationOptions: []
  }),
  methods: {
    updateLocation(term) {
      this.queryDestinations(term);
    },
    async queryDestinations(term) {
      if (term === "") return;
      try {
        const { data } = await this.$apollo.query({
          query: searchLocationQuery,
          variables: {
            search: term,
            limit: 12,
            countryCodes: [this.$i18n.locale],
            ...(parseInt(this.query) && { zipCode: this.query })
          }
        });

        this.locationOptions = data.searchLocation;
      } catch (e) {
        console.log("error", e.graphQLErrors);
      }
    },
    submit(data) {
      this.$emit("onUpdateProfile", data);
    }
  }
};
</script>

<style>
#update-profile-button {
  width: 100%;
}
</style>