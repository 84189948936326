<template>
  <HgAmadeusHotelTourSearch
    :is-mobile="isMobile"
    :is-loading-destinations="isLoading"
    :destinations="orderedDestinations"
    :ibe-url="searchUrl"
    :show-cooperation="showCooperation"
    :theme="theme"
    @onUpdateDestinationTerm="updateDestinationTerm"
    @onUpdateSearchParameters="updateSearchParameters"
  />
</template>

<script>
import autosuggestAmadeusQuery from "../../../apollo/queries/amadeus/suggest.gql"
import amadeusHotelSearchUrlQuery from "../../../apollo/queries/ibe/amadeusHotelSearchUrl.gql"

export default {
  data: () => ({
    destinationTerm: "",
    isLoading: false,
    orderedDestinations: [],
    searchUrl: "",
    isMobile: false
  }),
  props: {
    showCooperation: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: null
    }
  },
  created() {
    this.isMobile = window.innerWidth < 768

    setInterval(() => {
      let mobilePortal = document.querySelector("#mobile-portal")

      if (mobilePortal != null) {
        mobilePortal.classList.add("holidayguru")
      }
    }, 1000)
  },
  methods: {
    updateDestinationTerm(term) {
      this.destinationTerm = term
      this.queryDestinations()
    },
    async queryDestinations() {
      if (this.destinationTerm === "") return

      try {
        const {
          data: { autosuggestAmadeus }
        } = await this.$apollo.query({
          query: autosuggestAmadeusQuery,
          variables: {
            search: this.destinationTerm
          }
        })

        if (autosuggestAmadeus && autosuggestAmadeus.length > 0) {
          const orderTypes = []

          autosuggestAmadeus.forEach(elem => {
            if (!orderTypes.includes(elem.type)) {
              orderTypes.push(elem.type)
            }
          })

          // Order results by types
          const ordered = []

          orderTypes.forEach(element => {
            ordered.push(
              autosuggestAmadeus.filter(elem => {
                return elem.type === element
              })
            )
          })

          this.orderedDestinations = [].concat(...ordered)
        }
      } catch (e) {
        console.error(e.graphQLErrors)
      }
    },
    async updateSearchParameters(params) {
      const { destination, dateRange, duration, travelers } = params

      if (
        destination == null ||
        dateRange == null ||
        duration == null ||
        travelers == null
      ) {
        return
      }

      delete destination.__typename

      try {
        const {
          data: { amadeusHotelSearchUrl }
        } = await this.$apollo.query({
          query: amadeusHotelSearchUrlQuery,
          variables: {
            input: {
              agesOfChildren: travelers.ages,
              startDate: dateRange.start,
              endDate: dateRange.end,
              numberOfAdults: travelers.adults,
              destination
            }
          }
        })

        this.searchUrl = amadeusHotelSearchUrl
      } catch (e) {
        console.error(e.graphQLErrors)
      }
    }
  }
}
</script>
