<template>
  <div class="holidayguru">
    <AmadeusPackageTourSearch
      :show-cooperation="showCooperation"
      :theme="theme"
    ></AmadeusPackageTourSearch>
  </div>
</template>

<script>
import AmadeusPackageTourSearch from "../components/search/AmadeusPackageTourSearch.vue"

export default {
  components: {
    AmadeusPackageTourSearch
  },
  props: {
    showCooperation: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: null
    }
  }
}
</script>
