<template>
  <div class="holidayguru">
    <div class="content-top">
      <p>
        <strong
          >Nutzt eure Chance und gewinnt einen 1.000€ Reisegutschein</strong
        >
      </p>
      <p>Die Teilnahme ist einfach:</p>
      <ol class="description-list">
        <li>
          Registriert euch einmalig kostenlos
          <span>(Kontobestätigung via E-Mail erforderlich)</span> oder meldet
          euch bei eurem bestehenden Konto an.
        </li>
        <li>
          Vervollständigt euer Profil.
          <!-- <span
            >(Gewinnspielteilnahme via Klick auf Bestätigungslink in E-Mail
            erforderlich)</span > -->
        </li>
        <li>
          Klickt auf den „Jetzt teilnehmen“-Button.
          <span
            >(Mit dem Klick auf den Button stimmt ihr den Teilnahmebedingungen
            des Berge & Meer Gewinnspiels zu)</span
          >.
        </li>
        <li>
          Bestätigt die Gewinnspiel-Teilnahme via Klick auf den Link in der
          E-Mail.
        </li>
      </ol>
      <!-- ADVENTSKALENDER GEWINNSPIEL WORDING -->
      <!-- <p>
        Bei jeder weiteren Teilnahme reicht der Klick auf den „Jetzt
        teilnehmen“-Button, um im Lostopf zu landen.
      </p>
      <p>
        <strong>Tipp:</strong> Jeden Tag gibt es einen neuen Preis zu gewinnen.
        Macht also täglich wieder mit, um im Lostopf des Tages zu sein.
      </p> -->
      <p class="hint">
        <strong>Hinweis:</strong> Solltet ihr den Teilnahme-Button nicht direkt
        angezeigt bekommen, dann ruft die heutige Gewinnspielseite nochmal auf.
        Solltet ihr noch einen alten Account zur Nutzung unserer
        Kommentar-Funktion haben, könnt ihr die Anmeldedaten leider nicht
        nutzen. Registriert euch dann einfach kostenlos neu.
      </p>
      <p class="hint">
        <strong>Jetzt teilnehmen </strong> Mit dem Klick auf den “Jetzt
        teilnehmen”-Button bestätigt ihr die unten stehenden
        Teilnahmebedingungen des Berge & Meer Gewinnspiels.
      </p>
    </div>
    <div id="hg-raffle" class="hg-raffle">
      <!-- Raffle Prompt - Logged in User -->
      <template v-if="me && me.id">
        <template v-if="raffleParticipant || success != null">
          <!-- Successful participation -->
          <template v-if="success">
            <div class="message">
              <div>
                <p class="hg-title">
                  {{ $t("raffle.successTitle") }}
                </p>
                <p v-html="$t('raffle.successSubtitle')" />
              </div>
            </div>
          </template>
          <template v-else>
            <!-- Already participant -->
            <div class="message">
              <div>
                <p class="hg-title">
                  {{ $t("raffle.alreadyParticipantTitle") }}
                </p>
                <p v-html="$t('raffle.alreadyParticipantSubtitle')" />
              </div>
            </div>
          </template>
        </template>
        <!-- No Participant -->
        <template v-else-if="!raffleParticipant && me">
          <template v-if="isProfileIncomplete">
            <p class="tw-mt-4">
              Bitte vervollständige noch dein Profil, um teilzunehmen.
            </p>
            <br />
            <UpdateProfile :user="me" @onUpdateProfile="updateUser" />
          </template>

          <HgButton
            :disabled="
              (raffle && !raffle.isActive) || isProfileIncomplete || loading
            "
            color="teal"
            style="margin-top: 10px"
            @onClick="createParticipant"
          >
            {{ $t("raffle.participateButton") }}
          </HgButton>
          <br />
          <!-- <small v-if="raffle && raffle.isActive">
            {{ $t("raffle.clickNotice") }}
          </small> -->
          <small v-if="raffle && !raffle.isActive">
            {{ $t("raffle.inactive") }}
          </small>
        </template>
      </template>
      <!-- Login/Register Prompt -->
      <template v-else-if="!$apollo.loading && !me.id">
        <a
          class="hg-button hg-button--teal hg-button--regular login-button"
          @click="saveRedirectCookie"
          :href="loginUrl"
        >
          {{ $t("raffle.loginButton") }}
        </a>
        <br />
        <a
          class="hg-button register-button"
          @click="saveRedirectCookie"
          :href="registerUrl"
        >
          {{ $t("raffle.registerButton") }}
        </a>
      </template>
      <!-- Loading -->
      <template v-else> {{ $t("loading") }} </template>
    </div>
  </div>
</template>

<script>
import meQuery from "../../apollo/queries/auth/me.gql"
import raffleQuery from "../../apollo/queries/raffle/raffle.gql"
import raffleParticipantQuery from "../../apollo/queries/raffle/raffleParticipant.gql"
import createRaffleParticipantMutation from "../../apollo/mutations/raffle/createRaffleParticipant.gql"
import updateProfileMutation from "../../apollo/mutations/profile/updateUserProfile.gql"
import Cookies from "universal-cookie"

import UpdateProfile from "../components/modals/UpdateProfile.vue"

const image = require("../assets/guru_xmas.jpg")

export default {
  components: {
    UpdateProfile
  },
  props: {
    loginUrl: {
      type: String,
      default: ""
    },
    registerUrl: {
      type: String,
      default: ""
    },
    settingsUrl: {
      type: String,
      default: ""
    },
    raffleId: {
      type: String,
      default: ""
    },
    locale: {
      type: String,
      default: "de"
    }
  },
  data: () => ({
    image,
    success: null,
    raffleParticipant: null,
    loading: false,
    me: {},
    successTitle: "",
    loginText: ""
  }),
  computed: {
    isProfileIncomplete() {
      if (
        this.me.profile.sex === null ||
        this.me.profile.sex === "" ||
        this.me.profile.location.formatted === null ||
        this.me.profile.location.formatted === ""
      ) {
        return true
      } else {
        return false
      }
    }
  },
  mounted() {
    this.successTitle = this.$i18n.t("raffle.successTitle")
  },
  methods: {
    saveRedirectCookie() {
      const cookies = new Cookies()
      const redirectUrl = window.location.href
      cookies.set("hg-login-redirect", redirectUrl, { path: "/" })
    },
    async createParticipant() {
      this.loading = true

      const { data } = await this.$apollo.mutate({
        mutation: createRaffleParticipantMutation,
        errorPolicy: "all",
        variables: {
          input: {
            raffleId: this.raffleId,
            redirectUrl:
              "https://www.urlaubsguru.de/teilnahmebestaetigung-gewinnspiel/"
          }
        }
      })

      if (data) {
        this.raffleParticipant = data.createRaffleParticipant
        this.success = true
      } else {
        this.success = false
      }

      // gtm push competition_success
      if (process.env.NODE_ENV !== "development") {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "competition_success"
        })
      }
      this.loading = false
    },
    async updateUser(newProfile) {
      await this.$apollo.mutate({
        mutation: updateProfileMutation,
        variables: {
          firstName: newProfile.firstName,
          lastName: newProfile.lastName,
          lat: newProfile.lat,
          lng: newProfile.lng,
          sex: newProfile.sex.value
        }
      })

      this.$apollo.queries.me.refetch()
    }
  },
  apollo: {
    me: {
      skip() {
        return window.hasAuthToken === false
      },
      query: meQuery,
      fetchPolicy: "no-cache"
    },
    raffleParticipant: {
      query: raffleParticipantQuery,
      variables() {
        return {
          raffleId: this.raffleId
        }
      }
    },
    raffle: {
      query: raffleQuery,
      variables() {
        return {
          id: this.raffleId
        }
      }
    }
  }
}
</script>

<style>
.hg-raffle {
  font-size: 16px !important;
}

.hg-raffle h3,
.hg-raffle p {
  font-family: "Asap", Helvetica, Arial, sans-serif;
}
.hg-raffle h3 {
  font-weight: 600;
}

.hg-raffle p {
  max-width: 550px;
  line-height: 1.6;
  font-size: 16px;
}

.hg-raffle .message {
  width: 100%;
}

.message {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.message > div {
  max-width: 420px;
  margin-right: 2rem;
}

.hg-raffle .message img {
  max-width: 200px;
}

.hg-raffle .divider {
  margin: 0 1rem;
}

@media (max-width: 400px) {
  .hg-raffle .divider {
    display: block;
    margin: 0.5rem 0;
  }
}

.holidayguru > .hg-raffle > div > .hg-button {
  text-decoration: none !important;
  color: unset !important;
  font-size: 16px !important;
  width: 100% !important;
}

.hg-raffle .guru-logo,
.hg-raffle .partner-logo {
  width: 100%;
  max-width: 300px !important;
}

.hg-raffle .raffle-partner {
  margin-top: 2rem;
}

@media (max-width: 767px) {
  .hg-raffle .message > div {
    flex-direction: column;
  }
  .hg-raffle .message > div > div {
    margin-bottom: 2rem;
  }
}

.hg-raffle > .hg-button {
  font-weight: normal;
  width: 100% !important;
  margin-bottom: 20px;
}

.login-button {
  text-decoration: none !important;
  color: unset !important;
}

.register-button {
  color: var(--primary-color) !important;
  font-weight: bold !important;
  text-decoration: none !important;
}

.content-top p {
  margin-bottom: 15px;
}

.content-top .hint {
  font-size: 12px;
  margin-top: 30px;
}

.description-list {
  list-style-type: revert;
  margin: 30px 0 30px 20px;
}

.description-list li {
  margin-bottom: 10px;
}

.description-list li span {
  font-style: italic;
  color: #979797;
}
</style>
