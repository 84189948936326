<template>
  <section class="section holidayguru">
    <div v-if="isLoading">
      <HgLoadingBar :loading="isLoading" />
    </div>
    <div :class="`deal-widget--${partner}`">
      <div class="deal-grid">
        <!-- Deal Cards -->
        <HgDealCard
          v-for="deal in deals"
          :key="deal.id"
          :title="deal.title"
          :subtitle="deal.subtitle"
          :price="deal.price || deal.amadeusPrice"
          :image="deal.featuredImages[0].image"
          tag="a"
          :href="linkTarget || deal.url"
        />
      </div>
      <!-- Guru Bagde -->
      <div class="guru-badge">
        <small>Ein Angebot von </small>
        <guru-logo />
      </div>
    </div>
  </section>
</template>

<script>
import searchDealsQuery from "../../apollo/queries/deal/searchDeals.gql";

export default {
  props: {
    limit: {
      type: Number,
      default: 3
    },
    partner: {
      type: String,
      default: "holidayguru"
    },
    linkTarget: {
      type: String,
      default: ""
    },
    ignoreLocations: {
      type: String,
      default: ""
    },
    dealTypes: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    isLoading: true,
    deals: [],
    ignoreLocationsFilter: "",
    dealTypesFilter: ""
  }),
  mounted() {
    this.ignoreLocationsFilter = this.ignoreLocations;
    this.dealTypesFilter = this.dealTypes;
  },
  apollo: {
    searchDeals: {
      query: searchDealsQuery,
      variables() {
        let filter = {};
        if (this.ignoreLocationsFilter) {
          filter = {
            ignoreLocations: this.ignoreLocationsFilter
          };
        }

        if (this.dealTypesFilter) {
          let typesFilter = { types: this.dealTypesFilter.split(",") };

          filter = Object.assign(filter, typesFilter);
        }

        return {
          filter,

          limit: this.limit,
          sort: "DATE_DESC",
          offset: 0,
          affiliateHost: this.originalOrigin
        };
      },
      result({ data }) {
        if (!data) return;
        this.deals = data.searchDeals.filter(deal => deal.id);
        this.totalDeals = data._searchDealsMeta.count;
        this.maxPage = Math.ceil(this.totalDeals / this.pageSize);
        this.showMoreEnabled =
          this.currentPage * this.pageSize < this.totalDeals;
      }
    }
  }
};
</script>

<style scoped>
.deal-grid {
  max-width: 920px;
  margin: 3rem auto 1rem;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 1.5rem;
}

.guru-badge {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.guru-badge > * {
  margin: 0 0 0 0.5rem;
  white-space: nowrap;
}

.guru-badge >>> .logo {
  height: 2rem;
  width: unset;
}

@media screen and (max-width: 720px) {
  .deal-grid >>> .deal-card:nth-child(4) {
    display: none;
  }

  .deal-grid >>> .deal-card:nth-child(5),
  .deal-grid >>> .deal-card:nth-child(6),
  .deal-grid >>> .deal-card:nth-child(7),
  .deal-grid >>> .deal-card:nth-child(8),
  .deal-grid >>> .deal-card:nth-child(9) {
    display: none;
  }
}
</style>
