<template>
  <div class="holidayguru">
    <div :class="`deal-widget--${partner}`">
      <div v-if="!hideAdvertiser" class="hg-ad-notice">
        <div class="badge">
          ANZEIGE
        </div>
        <div class="content">
          <small>Ein Angebot von </small>
          <button class="transparent-button" @click="showImprint = true">
            <guru-logo />
          </button>
        </div>
      </div>

      <HgModal
        class="imprint-modal"
        v-if="showImprint"
        @onClose="showImprint = false"
      >
        <div class="tw-p-4 hg-content">
          <h2 class="hg-title">Impressum</h2>
          <p>
            Urlaubsguru GmbH <br />
            Rhenus Platz 2 <br />
            59439 Holzwickede <br />
          </p>
          <h4>
            Der direkte Draht zum Urlaubsguru:
          </h4>
          <p>
            Per Mail:
            <a href="mailto:info@urlaubsguru.de">
              info@urlaubsguru.de
            </a>
            <br />
            Per Telefon:
            <a href="tel:+492301945800">
              +49 23 01 / 945 80-0
            </a>
          </p>
          <h4>
            Vertreten durch die Geschäftsführer
          </h4>
          <p>
            Daniel Krahn und Daniel Marx
          </p>
          <h4>
            Registereintrag
          </h4>
          <p>
            Eintragung im Handelsregister. <br />
            Registergericht: Hamm <br />
            Registernummer: HRB 8204 <br />
          </p>
          <h4>
            Umsatzsteuer-ID
          </h4>
          <p>
            Umsatzsteuer-Identifikationsnummer gemäß <br />
            §27 a Umsatzsteuergesetz:
            <br />
            DE295652564
          </p>
        </div>
      </HgModal>
      <div v-if="showDealsNav" class="hg-deals-heading-nav">
        <a href="#!">Deals</a>
        <a :href="tabUrl">{{ tabLabel }}</a>
      </div>
      <div
        v-if="showHeader || showTags"
        class="hg-deals-heading-wrapper"
        :class="
          !showDealsNav ? 'tw-rounded-3' : 'tw-rounded-3 tw-rounded-tl-none'
        "
      >
        <HgLoadingBar :loading="isLoading" />
        <div v-if="showHeader" class="heading">
          <h1 class="hg-title">{{ title }}</h1>
          <!-- <span v-if="partner" class="partner">{{ partner }}</span> -->
        </div>
        <div v-if="showTags" class="hg-tags-container">
          <div class="hg-category-label">Kategorien wählen:</div>
          <div class="hg-tags">
            <HgTag
              v-for="tag in allTags"
              :key="tag.id"
              @onClick="clickTag(tag)"
              :active="activeTagIds.includes(tag.id)"
              show-toggle
              class="hg-tag-button"
              :tag="tag"
            />
          </div>
        </div>
      </div>
      <a v-if="showImage" class="hg-deals-heading-image" :href="tabUrl">
        <img :src="image" alt="Pauschalreisen entdecken" />
      </a>
      <!-- TODO: WEITERMACHEN -->
      <!-- {{ deals }} -->
      <div class="deal-grid">
        <!-- <deal-card v-for="deal in deals" :key="deal.id" :deal="deal" /> -->
        <HgDealCard
          v-for="deal in deals"
          :key="deal.id"
          :title="deal.title"
          :subtitle="deal.subtitle"
          :price="deal.price || deal.amadeusPrice"
          :show-cta="showCta"
          :image="deal.featuredImages[0].image"
          tag="a"
          :href="deal.url"
          :class="`deal-card--${partner}`"
        />
      </div>
      <div v-if="isLoading">
        <HgLoadingBar :loading="isLoading" />
      </div>
      <!-- Load more -->
      <div v-if="showMoreEnabled" class="load-more-bar">
        <HgButton
          class="hg-deals-button"
          color="teal"
          :loading="$apollo.loading"
          @onClick="nextPage"
        >
          <template v-if="$apollo.loading">
            Lade...
          </template>
          <template v-else>
            Weitere Deals anzeigen
          </template>
        </HgButton>
      </div>
      <div v-if="!showMoreEnabled" class="no-more-info">
        <span>keine weiteren Angebote vorhanden</span>
      </div>
      <div class="hg-whitelabel-footer">
        <button
          v-if="partner === 'familie' || partner === 'desired'"
          class="hg-button"
          @click="openPartnerDataProtection"
        >
          Datenschutzerklärung
        </button>
        <button class="hg-button" @click="showImprint = true">
          Impressum
        </button>
        <button v-if="layerId" class="hg-button" @click="showCookieLayer">
          Cookies
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import searchDealsQuery from "../../apollo/queries/deal/searchDeals.gql"
/* import DealHeader from '../components/DealHeader.vue'; */
const image = require("../assets/spiegel_pauschalreisen_banner.jpg")

export default {
  /* components: {
    DealHeader,
  }, */
  props: {
    title: String,
    partner: String,
    tags: String,
    showHeader: String,
    showDealsNav: Boolean,
    showTags: Boolean,
    showImage: Boolean,
    /*'imageUrl',*/
    tabLabel: String,
    tabUrl: String,
    linkToGuru: String,
    showMoreDeals: Boolean,
    hideAdvertiser: Boolean,
    limit: String,
    originalOrigin: String,
    layerId: String,
    dealTypes: String,
    ignoreLocations: Boolean,
    partnerDataProtection: String,
    showCta: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    image,
    skipQuery: false,
    isLoading: false,
    searchDeals: null,
    deals: null,
    showImprint: false,
    isInitialLoad: true,
    searchTerm: "",
    currentPage: 1,
    totalDeals: 300,
    maxPage: 0,
    pageSize: 12,
    showMoreEnabled: true,
    activeTagIds: [],
    ignoreLocationsFilter: "",
    dealTypesFilter: "",
    allTags: [
      {
        name: "Flexibel stornierbar",
        id: "7a3e5c7e-c321-4332-95c5-f8dc01aca629"
      },
      {
        name: "Hotels",
        id: "51ab8265-7730-4956-90ac-4d1d301fddef"
      },
      {
        name: "Deutschland",
        id: "7ee86024-bb6c-4640-af59-337515e4c29a"
      },
      {
        name: "Pauschalreisen",
        id: "61b41f42-ad02-43b6-8b9c-84f76df54e63"
      }
    ]
  }),
  mounted() {
    this.perPage = this.limit ? this.limit : this.perPage
    this.ignoreLocationsFilter = this.ignoreLocations
    this.dealTypesFilter = this.dealTypes
  },
  methods: {
    clickTag(tag) {
      if (this.activeTagIds.includes(tag.id)) {
        this.activeTagIds.splice(this.activeTagIds.indexOf(tag.id), 1)
      } else {
        this.activeTagIds.push(tag.id)
      }
    },
    nextPage() {
      this.currentPage += 1
      this.fetchMore()
    },
    fetchMore() {
      let filter = {
        searchTerm: this.searchTerm
      }
      const { tags } = this.$route.query
      if (tags) {
        filter.tagIds = tags
      }
      this.$apollo.queries.searchDeals.fetchMore()
    },
    showCookieLayer() {
      window._sp_.loadPrivacyManagerModal(this.layerId)
    },
    openPartnerDataProtection() {
      window.location.href = this.partnerDataProtection
    }
  },

  apollo: {
    searchDeals: {
      query: searchDealsQuery,
      variables() {
        let filter = {
          // searchTerm: this.searchTerm,
        }
        if (this.activeTagIds.length) {
          filter.tagIds = this.activeTagIds
        }

        if (this.ignoreLocationsFilter) {
          filter = {
            ignoreLocations: this.ignoreLocationsFilter
          }
        }

        if (this.dealTypesFilter) {
          let typesFilter = { types: this.dealTypesFilter.split(",") }

          filter = Object.assign(filter, typesFilter)
        }

        return {
          filter,
          limit: this.pageSize * this.currentPage,
          sort: "DATE_DESC",
          offset: 0,
          affiliateHost: this.originalOrigin
        }
      },
      result({ data }) {
        if (!data) return
        this.deals = data.searchDeals.filter(deal => deal.id)
        this.totalDeals = data._searchDealsMeta.count
        this.maxPage = Math.ceil(this.totalDeals / this.pageSize)
        this.showMoreEnabled =
          this.currentPage * this.pageSize < this.totalDeals
      }
    }
  }
}
</script>

<style lang="postcss" scoped>
.hg-deals-wrapper {
  padding: 8px;
}

.hg-deals-heading-wrapper .bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  margin: 0;
  background-color: transparent;
}

.hg-deals-heading-wrapper {
  background-color: #e8fbf9;
  background-color: #b1f2ec;
  padding: 15px;
}

.hg-deals-heading-image img {
  border-radius: 8px;
  margin: 24px auto;
  width: calc(100% - 64px);
  position: relative;
}

.hg-deals-heading-nav {
  display: flex;
  flex-direction: row;
}

.hg-deals-heading-nav a {
  padding: 12px 24px;
  border-radius: 8px 8px 0px 0px;
  color: #363636;
}

.hg-deals-heading-nav a:first-child {
  background-color: #b2f2ec;
}

.hg-deals-button {
  font-size: 16px;
}

.heading {
  position: relative;
}

.heading h1 {
  font-size: 32px;
  margin-bottom: 16px;
  margin-top: 4px;
  font-weight: 700;
}

.partner {
  position: absolute;
  top: 0;
  left: 0%;
  background-color: royalblue;
  color: white;
  padding: 4px 4px;
}

.load-more-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px;
}
.load-more-bar button {
  margin-top: 32px;
  width: 240px;
}
.no-more-info {
  text-align: center;
  margin-top: 64px;
}

.hg-tag-button {
  margin-right: 8px;
  margin-top: 8px;
}

.hg-category-label {
  margin-bottom: 8px;
}

.hg-ad-notice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #dcfaf5; */
  padding: 16px 8px;
  margin-bottom: 8px;
}

.hg-ad-notice .badge {
  padding: 4px 8px;
  background-color: #f1efed;
  font-size: 12px;
}

.hg-ad-notice .content {
  display: flex;
  white-space: nowrap;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 460px) {
  .hg-ad-notice .content {
    flex-direction: row;
  }
}

button {
  appearance: auto;
  border: none;
}

.hg-ad-notice .content button {
  height: 32px;
  padding: 2px;
  margin-bottom: 4px;
}

.hg-title {
  border: none;
}

.imprint-modal p {
  margin: 8px 0 16px;
}
</style>

<style lang="postcss">
.deal-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 24px;
  margin-top: 24px;
}

.hg-ad-notice .content button div,
.hg-ad-notice .content button svg {
  height: 100%;
  width: 145px;
}

.hg-modal-content {
  width: 100%;
}

.OffsetContainer {
  position: relative;
  z-index: 999;
}

.hg-content h2 {
  font-size: 28px;
  font-weight: 700;
}
.hg-content h4 {
  font-size: 20px;
  font-weight: 700;
}
.hg-content p {
  margin: 0 0 8px;
  line-height: 1.6;
}

.hg-content a {
  color: #0ab;
}

.theme-default-content.content__default {
  /* background-color: #f1efed; */
}
.transparent-button {
  background-color: transparent;
}

.hg-tags {
  @apply tw-flex tw-flex-wrap;
}
.hg-tags .tag-button {
  flex-basis: 0;
  @apply tw-flex-grow-0 tw-flex-shrink;
}

.hg-whitelabel-footer {
  @apply tw-flex tw-justify-end tw-pt-6 tw-pb-8;
}
.hg-whitelabel-footer button {
  @apply tw-w-auto hover:tw-underline;
}

.deal-widget--guru {
  --primary-color: #00d5df;
}

/** PARTNER STYLES */
/** rp-online */
.deal-widget--rp-online .deal-card,
.deal-widget--rp-online .hg-deals-heading-nav a,
.deal-widget--rp-online .deal-card .hg-price,
.deal-widget--rp-online .hg-category-label,
.deal-widget--rp-online .deal-card .price-span,
.deal-widget--rp-online button.hg-button,
.deal-widget--rp-online .hg-modal-content {
  font-family: "Source Sans Pro", sans-serif;
}

.deal-widget--rp-online .hg-title,
.deal-widget--rp-online .deal-card .deal-button h2 {
  font-family: "Merriweather", serif !important;
}

.deal-widget--rp-online .deal-card .deal-button h2 {
  @apply tw-mb-2;
}

.deal-widget--rp-online .deal-card .deal-button p {
  @apply tw-mt-0;
  font-size: 16px;
}

.deal-widget--rp-online .hg-modal-content a {
  color: #2f2f2f;
}

.deal-widget--rp-online {
  @apply tw-p-8;
}

.deal-widget--rp-online .hg-deals-heading-wrapper,
.deal-widget--mhs .hg-deals-heading-wrapper {
  background-color: #edf0f3;
}
.deal-widget--familie .hg-deals-heading-wrapper {
  background-color: #f0f4f5;
}
.deal-widget--desired .hg-deals-heading-wrapper {
  background-color: #f5f5f5;
}

.deal-widget--rp-online .deal-card a {
  text-decoration: none !important;
}

.deal-widget--rp-online .deal-card .deal-content {
  padding: 10px 15px;
}

.deal-widget--rp-online .deal-card,
.deal-widget--rp-online .hg-deal-price,
.deal-widget--rp-online .image-area,
.deal-widget--rp-online .deal-image,
.deal-widget--rp-online .hg-deals-heading-wrapper,
.deal-widget--rp-online .hg-deals-heading-nav a,
.deal-widget--rp-online .hg-deals-heading-image img,
.deal-widget--rp-online .bg-fade,
.deal-widget--rp-online .tag-button,
.deal-widget--rp-online a.deal-button {
  @apply tw-rounded-none;
}

.deal-widget--rp-online .hg-deals-heading-nav {
  display: inline-flex;
  line-height: 1.33333;
}

.deal-widget--rp-online .hg-deals-heading-nav a {
  @apply tw-relative tw-inline-block tw-bg-white;
  font-size: 19px;
  padding: 0 7px;
  border-bottom: 1px solid;
  border-bottom-color: #ccc;
  vertical-align: baseline;
  text-decoration: none;
}

.deal-widget--rp-online .hg-deals-heading-nav a:after {
  content: "";
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  height: 0;
  margin-top: -1px;
  background-color: #ccc;
}

.deal-widget--rp-online .hg-deals-heading-nav a:hover:after {
  height: 2px;
}

.deal-widget--rp-online .hg-deals-heading-nav a:first-child {
  background-color: #fff;
  border-bottom-color: #ffbe00;
}

.deal-widget--rp-online .hg-deals-heading-nav a:first-child:after {
  background-color: #ffbe00;
  height: 3px;
  transform: scale(1);
}

.deal-widget--rp-online a.deal-button,
.deal-widget--rp-online a.deal-button::before,
.deal-widget--rp-online a.deal-button::after {
  @apply tw-border-none;
}

.deal-widget--rp-online button.hg-button--regular {
  border-radius: 2px;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 12%), 0 2px 2px 0 rgb(0 0 0 / 24%);
}

.deal-widget--rp-online .hg-icon * {
  opacity: 1 !important;
  animation-fill-mode: none !important;
}

.tag-button:hover {
  color: #fff;
  background-color: var(--primary-color);
}

.tag-button:focus {
  background-color: #fff;
  box-shadow: 0 0 6px 3px #dd590040;
}
.tag-button.active:active,
.tag-button.active,
.tag-button:active {
  color: #fff;
  background-color: var(--primary-color);
}

/* DESIRED */
.deal-widget--desired .deal-content h2 {
  font-size: 20px;
}
.deal-widget--desired .deal-content {
  margin-bottom: 8px;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.deal-widget--desired .subtitle {
  font-size: 14px;
}
.deal-widget--desired .pricesize--2xl {
  font-size: 24px;
  margin-left: 4px;
  margin-right: 4px;
  line-height: 24px;
  height: 24px;
}
.deal-widget--desired .hg-deal-price {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
}
.deal-widget--desired .hg-category-label {
  font-size: 26px;
}
.deal-widget--desired .hg-tags {
  font-size: 26px;
}
.deal-widget--desired .tag-button {
  font-size: 14px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 4px;
  line-height: 20px;
}

.deal-widget--desired button.hg-button--regular {
  height: 48px;
  padding-left: 14px;
  padding-right: 14px;
}

/* FAMILIE */
.deal-widget--familie .deal-content h2 {
  font-size: 20px;
}
.deal-widget--familie .deal-content {
  margin-bottom: 8px;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.deal-widget--familie .subtitle {
  font-size: 14px;
}
.deal-widget--familie .pricesize--2xl {
  font-size: 24px;
  margin-left: 4px;
  margin-right: 4px;
  line-height: 24px;
  height: 24px;
}
.deal-widget--familie .hg-deal-price {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
}
.deal-widget--familie .hg-category-label {
  font-size: 26px;
}
.deal-widget--familie .hg-tags {
  font-size: 26px;
}
.deal-widget--familie .tag-button {
  font-size: 14px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 4px;
  line-height: 20px;
}

.deal-widget--familie button.hg-button--regular {
  height: 48px;
  padding-left: 14px;
  padding-right: 14px;
}

/** PARTNER STYLES END */

/*
.deal-widget--dorstenerzeitung .deal-card .deal-button,
.deal-widget--halternerzeitung .deal-card .deal-button,
.deal-widget--hellwegeranzeiger .deal-card .deal-button,
.deal-widget--ruhr-nachrichten .deal-card .deal-button {
  --primary-color: #49a4de;
  --primary-color-active: #49a4de;
  --primary-color-hover: #1e2b46;
  --primary-text-color: #fff;
  --primary-text-color-hover: #fff;
  --tw-ring-color: #49a4de;
}

.deal-widget--dorstenerzeitung .deal-card .deal-button h2,
.deal-widget--halternerzeitung .deal-card .deal-button h2,
.deal-widget--hellwegeranzeiger .deal-card .deal-buttonh2,
.deal-widget--ruhr-nachrichten .deal-card .deal-button h2,
.deal-widget--muensterlandzeitung .deal-card .deal-button h2 {
  font-family: "HarrisonSerifPro", serif;
}

.deal-widget--dattelner-morgenpost .deal-card .deal-button,
.deal-widget--hertener-allgemeine .deal-card .deal-button,
.deal-widget--marler-zeitung .deal-card .deal-button,
.deal-widget--recklinghaeuser-zeitung .deal-card .deal-button,
.deal-widget--stimberg-zeitung .deal-card .deal-button,
.deal-widget--waltroper-zeitung .deal-card .deal-button {
  --primary-color: #00a550;
  --primary-color-active: #00a550;
  --primary-color-hover: #0d7a42;
  --primary-text-color: #fff;
  --primary-text-color-hover: #fff;
  --tw-ring-color: #00a550;
}

.deal-widget--dattelner-morgenpost .deal-card .deal-button h2,
.deal-widget--hertener-allgemeine .deal-card .deal-button h2,
.deal-widget--marler-zeitung .deal-card .deal-button h2,
.deal-widget--recklinghaeuser-zeitung .deal-card .deal-button h2,
.deal-widget--stimberg-zeitung .deal-card .deal-button h2,
.deal-widget--waltroper-zeitung .deal-card .deal-button h2 {
  font-family: "HarrisonSerifPro", serif;
}
*/
</style>
