<template>
  <div class="holidayguru">
    <ShortTripSearch :show-cooperation="showCooperation"></ShortTripSearch>
  </div>
</template>

<script>
import ShortTripSearch from "../components/search/ShortTripSearch.vue"

export default {
  components: {
    ShortTripSearch
  },
  props: {
    showCooperation: {
      type: Boolean,
      default: false
    }
  }
}
</script>
