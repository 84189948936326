<template>
  <div class="holidayguru">
    <AmadeusHotelTourSearch
      :show-cooperation="showCooperation"
      :theme="theme"
    ></AmadeusHotelTourSearch>
  </div>
</template>

<script>
import AmadeusHotelTourSearch from '../components/search/AmadeusHotelTourSearch.vue'

export default {
  components: {
    AmadeusHotelTourSearch
  },
  props: {
    showCooperation: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: null
    }
  }
}
</script>
