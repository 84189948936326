<template>
  <div class="holidayguru partner-spiegel">
    <div id="hg-deals hg-reset">
      <div class="hg-ad-notice">
        <div class="badge">
          ANZEIGE
        </div>
        <div class="content">
          <small>Ein Angebot von </small>
          <button class="transparent-button" @click="showImprint = true">
            <guru-logo />
          </button>
        </div>
      </div>
      <div class="hg-deals-wrapper">
        <HgModal
          class="imprint-modal"
          v-if="showImprint"
          @onClose="showImprint = false"
        >
          <div class="tw-p-4 hg-content">
            <h2 class="hg-title">Impressum</h2>
            <p>
              Urlaubsguru GmbH <br />

              Rhenus Platz 2 <br />

              59439 Holzwickede <br />
            </p>

            <h4>
              Der direkte Draht zum Urlaubsguru:
            </h4>
            <p>
              Per Mail:
              <a href="mailto:info@urlaubsguru.de">
                info@urlaubsguru.de
              </a>
              <br />

              Per Telefon:
              <a href="tel:+492301945800">
                +49 23 01 / 945 80-0
              </a>
            </p>

            <h4>
              Vertreten durch die Geschäftsführer
            </h4>
            <p>
              Daniel Krahn und Daniel Marx
            </p>

            <h4>
              Registereintrag
            </h4>
            <p>
              Eintragung im Handelsregister. <br />
              Registergericht: Hamm <br />
              Registernummer: HRB 8204 <br />
            </p>

            <h4>
              Umsatzsteuer-ID
            </h4>

            <p>
              Umsatzsteuer-Identifikationsnummer gemäß <br />
              §27 a Umsatzsteuergesetz:
              <br />
              DE295652564
            </p>
          </div>
        </HgModal>

        <div class="hg-deals-heading-nav">
          <a href="#!">Deals</a>
          <a href="https://pauschalreisen-spiegel.urlaubsguru.de/"
            >Pauschalreisen</a
          >
        </div>

        <div class="hg-deals-heading-wrapper">
          <HgLoadingBar :loading="isLoading" />
          <div v-if="showHeader" class="heading">
            <h1 class="hg-title">{{ title }}</h1>
            <span v-if="partner" class="partner">{{ partner }}</span>
          </div>

          <div class="hg-category-label">Kategorien wählen:</div>

          <div class="hg-tags">
            <HgTag
              v-for="tag in allTags"
              :key="tag.id"
              @onClick="clickTag(tag)"
              :active="activeTagIds.includes(tag.id)"
              show-toggle
              class="hg-tag-button"
              :tag="tag"
            />
          </div>
        </div>

        <a
          class="hg-deals-heading-image"
          href="https://pauschalreisen-spiegel.urlaubsguru.de/"
        >
          <img :src="image" alt="Pauschalreisen entdecken" />
        </a>

        <div class="grid">
          <HgDealCard
            v-for="deal in deals"
            :key="deal.id"
            :title="deal.title"
            :subtitle="deal.subtitle"
            :price="deal.price || deal.amadeusPrice"
            show-cta
            :image="deal.featuredImages[0].image"
            tag="a"
            :href="deal.url"
          />
        </div>
        <div v-if="isLoading">
          <HgLoadingBar :loading="isLoading" />
        </div>

        <!-- Load more -->
        <div v-if="showMoreEnabled" class="load-more-bar">
          <HgButton color="teal" :loading="$apollo.loading" @onClick="nextPage">
            <template v-if="$apollo.loading">
              Lade...
            </template>
            <template v-else>
              Weitere Deals anzeigen
            </template>
          </HgButton>
        </div>

        <div v-if="!showMoreEnabled" class="no-more-info">
          <span>keine weiteren Angebote vorhanden</span>
        </div>
        <div class="hg-whitelabel-footer">
          <button class="hg-button" @click="showImprint = true">
            Impressum
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import searchDealsQuery from "../../apollo/queries/deal/searchDeals.gql";
const image = require("../assets/spiegel_pauschalreisen_banner.jpg");

export default {
  name: "App",
  props: [
    "title",
    "partner",
    "tags",
    "showHeader",
    "linkToGuru",
    "showMoreDeals",
    "limit",
    "originalOrigin"
  ],
  data: () => ({
    image,
    skipQuery: false,
    isLoading: false,
    searchDeals: null,
    deals: null,
    showImprint: false,
    isInitialLoad: true,
    searchTerm: "",
    currentPage: 1,
    totalDeals: 300,
    maxPage: 0,
    pageSize: 12,
    showMoreEnabled: true,
    activeTagIds: [],
    allTags: [
      {
        name: "Flexibel stornierbar",
        id: "7a3e5c7e-c321-4332-95c5-f8dc01aca629"
      },
      {
        name: "Hotels",
        id: "51ab8265-7730-4956-90ac-4d1d301fddef"
      },
      {
        name: "Deutschland",
        id: "7ee86024-bb6c-4640-af59-337515e4c29a"
      },
      {
        name: "Pauschalreisen",
        id: "61b41f42-ad02-43b6-8b9c-84f76df54e63"
      }
    ]
  }),
  mounted() {
    this.perPage = this.limit ? this.limit : this.perPage;
  },
  methods: {
    clickTag(tag) {
      if (this.activeTagIds.includes(tag.id)) {
        this.activeTagIds.splice(this.activeTagIds.indexOf(tag.id), 1);
      } else {
        this.activeTagIds.push(tag.id);
      }
    },
    nextPage() {
      this.currentPage += 1;
      this.fetchMore();
    },
    fetchMore() {
      let filter = {
        searchTerm: this.searchTerm
      };
      const { tags } = this.$route.query;
      if (tags) {
        filter.tagIds = tags;
      }
      this.$apollo.queries.searchDeals.fetchMore();
    }
  },

  apollo: {
    searchDeals: {
      query: searchDealsQuery,
      variables() {
        let filter = {
          // searchTerm: this.searchTerm,
        };
        if (this.activeTagIds.length) {
          filter.tagIds = this.activeTagIds;
        }

        return {
          filter,
          limit: this.pageSize * this.currentPage,
          sort: "DATE_DESC",
          offset: 0,
          affiliateHost: this.originalOrigin
        };
      },
      result({ data }) {
        if (!data) return;
        this.deals = data.searchDeals.filter(deal => deal.id);
        this.totalDeals = data._searchDealsMeta.count;
        this.maxPage = Math.ceil(this.totalDeals / this.pageSize);
        this.showMoreEnabled =
          this.currentPage * this.pageSize < this.totalDeals;
      }
    }
  }
};
</script>

<style scoped lang="postcss">
#hg-deals {
  font-family: "Asap", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  padding: 0;
  background-color: #fff;
  position: relative;
  box-sizing: border-box;
}

.hg-deals-wrapper {
  padding: 0.5rem;
}

.hg-deals-heading-wrapper .bar {
  position: absolute;
  top: 0;
  left: 0rem;
  right: 0rem;
  width: 100%;
  margin: 0;
  background-color: transparent;
}

.hg-deals-heading-wrapper {
  background-color: #e8fbf9;
  background-color: #b1f2ec;
  padding: 1.5rem;
  border-radius: 0rem 0rem 0.5rem 0.5rem;
}

.hg-deals-heading-image img {
  border-radius: 0.5rem;
  margin: 1.5rem auto;
  width: calc(100% - 4rem);
  position: relative;
}

.hg-deals-heading-nav {
  display: flex;
  flex-direction: row;
}

.hg-deals-heading-nav a {
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem 0.5rem 0rem 0rem;
  color: #363636;
}

.hg-deals-heading-nav a:first-child {
  background-color: #b2f2ec;
}

.heading {
  position: relative;
}

.heading h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: 700;
}

.partner {
  position: absolute;
  top: 0;
  left: 0%;
  background-color: royalblue;
  color: white;
  padding: 0.25rem 0.25rem;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 1.5rem;
  margin-top: 1.5rem;
}
.load-more-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.5rem;
}
.no-more-info {
  text-align: center;
  margin-top: 4rem;
}

.hg-tag-button {
  margin-right: 0.5rem;
  margin-top: 0.5rem;
}

.hg-category-label {
  margin-bottom: 0.5rem;
}

.hg-ad-notice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #dcfaf5; */
  padding: 1rem 0.5rem 1.5rem 0.5rem;
}

.hg-ad-notice .badge {
  padding: 0.25rem 0.5rem;
  background-color: #f1efed;
  font-size: 0.75rem;
}

.hg-ad-notice .content {
  display: flex;
  white-space: nowrap;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 460px) {
  .hg-ad-notice .content {
    flex-direction: row;
  }
}

button {
  appearance: auto;
  border: none;
}

.hg-ad-notice .content button {
  height: 2rem;
  padding: 0.15rem;
  margin-bottom: 0.25rem;
}

/*.hg-whitelabel-footer {
  @apply tw-flex tw-justify-center tw-p-8;
}*/

.hg-title {
  border: none;
}

.imprint-modal p {
  margin: 0.5rem 0 1rem;
}
@media (prefers-color-scheme: dark) {
  .hg-ad-notice {
    background-color: rgba(31, 30, 28, 1);
  }
  .hg-ad-notice .content {
    background-color: var(--primary-background-color--darkmode);
    color: var(--primary-text-color--darkmode);
    padding-left: 1rem;
  }
  .hg-ad-notice .badge {
    background-color: var(--primary-background-color--darkmode);
    color: var(--primary-text-color--darkmode);
  }
  .hg-deals-wrapper {
    background-color: rgba(31, 30, 28, 1);
    color: #fff;
  }
  .hg-deals-heading-wrapper,
  .hg-deals-heading-nav a:first-child {
    @apply tw-text-white tw-bg-teal-600;
  }
  .hg-deals-heading-nav a {
    @apply tw-text-gray-300 tw-bg-teal-700;
  }
  .tag-button {
    background-color: var(--primary-background-color--darkmode);
    color: var(--primary-text-color--darkmode);
  }
  .tag-button.active {
    @apply tw-bg-teal-500;
  }
  .tag-button.focus {
    box-shadow: 0 0 6px 3px rgb(36 74 79 / 40%);
  }
  .hg-content {
    background-color: var(--primary-background-color--darkmode);
    color: var(--primary-text-color--darkmode);
  }
  .deal-card {
    color: var(--primary-text-color--darkmode);
  }
  .load-more-bar * {
    color: var(--primary-text-color--darkmode);
  }
  .hg-deal-price {
    background-color: var(--primary-background-color--darkmode);
  }
}
</style>

<style lang="postcss">
.hg-ad-notice .content button div,
.hg-ad-notice .content button svg {
  height: 100%;
  width: 145px;
}

.hg-modal-content {
  width: 100%;
}

.OffsetContainer {
  position: relative;
  z-index: 999;
}

.hg-content h2 {
  font-size: 1.75rem;
  font-weight: 700;
}
.hg-content h4 {
  font-size: 1.25rem;
  font-weight: 700;
}
.hg-content p {
  margin: 0 0 0.5rem;
  line-height: 1.6;
}

.hg-content a {
  color: #0ab;
}

.theme-default-content.content__default {
  /* background-color: #f1efed; */
}
.transparent-button {
  background-color: transparent;
}

.hg-tags {
  @apply tw-flex tw-flex-wrap;
}
.hg-tags .tag-button {
  flex-basis: 0;
  @apply tw-flex-grow-0 tw-flex-shrink;
}
</style>
