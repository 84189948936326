<template>
  <div>
    <HgShortTripSearch
      :is-mobile="isMobile"
      :destinations="orderedDestinations"
      :search-url="searchUrl"
      :show-cooperation="showCooperation"
      :theme="theme"
      @onUpdateDestinationTerm="updateDestinationTerm"
      @onUpdateSearchParameters="updateSearchParameters"
    />
  </div>
</template>

<script>
import travelcircusAutosuggestQuery from "../../../apollo/queries/ibe/travelcircusAutosuggest.gql"
import travelcircusShortTripsSearchUrlQuery from "../../../apollo/queries/ibe/travelcircusShortTripsSearchUrl.gql"

export default {
  data: () => ({
    selectedDaterange: null,
    selectedTravelers: { adults: 2 },
    destinationTerm: "",
    orderedDestinations: [],
    searchUrl: "",
    isMobile: false
  }),

  props: {
    showCooperation: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: null
    }
  },

  created() {
    this.isMobile = window.innerWidth < 768

    setInterval(() => {
      let mobilePortal = document.querySelector("#mobile-portal")

      if (mobilePortal != null) {
        mobilePortal.classList.add("holidayguru")
      }
    }, 1000)
  },

  methods: {
    async updateSearchParameters(event) {
      const { destination, dateRange, travelers } = event
      if (destination == null || dateRange == null || travelers == null) {
        return
      }

      this.selectedDaterange = event.dateRange
      this.selectedTravelers = event.travelers || this.selectedTravelers

      delete destination.__typename

      try {
        const {
          data: { travelcircusShortTripsSearchUrl }
        } = await this.$apollo.query({
          query: travelcircusShortTripsSearchUrlQuery,
          variables: {
            input: {
              agesOfChildren: travelers.ages,
              checkinDate: dateRange.start,
              checkoutDate: dateRange.end,
              numberOfAdults: travelers.adults,
              destination
            }
          }
        })

        this.searchUrl = travelcircusShortTripsSearchUrl
      } catch (e) {
        console.error(e.graphQLErrors)
      }
    },

    updateDestinationTerm(term) {
      this.destinationTerm = term
      this.queryDestinations()
    },

    async queryDestinations() {
      if (this.destinationTerm === "") return

      try {
        const {
          data: { travelcircusAutosuggest }
        } = await this.$apollo.query({
          query: travelcircusAutosuggestQuery,
          variables: {
            search: this.destinationTerm
          }
        })

        if (travelcircusAutosuggest && travelcircusAutosuggest.length > 0) {
          const orderTypes = []

          travelcircusAutosuggest.forEach(elem => {
            if (!orderTypes.includes(elem.type)) {
              orderTypes.push(elem.type)
            }
          })

          // Order results by types
          const ordered = []

          orderTypes.forEach(element => {
            ordered.push(
              travelcircusAutosuggest.filter(elem => {
                return elem.type === element
              })
            )
          })

          this.orderedDestinations = [].concat(...ordered)
        }
      } catch (e) {
        console.error(e.graphQLErrors)
      }
    }
  }
}
</script>
